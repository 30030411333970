
.login-content{
    display: flex;
    justify-content: space-around;
}

.card-login{
    border: 1px solid #dee2e6;
    border-radius: 2%;
}

.d-block-left{
    margin-right: 15px;
}

.d-block-status{
    text-align: center;
}

.radio_status{
    margin: 0;
}

.prod-block-right{
    margin-bottom: 15px;
}

.table-filter{
    margin: 0 auto;
    margin-right: 0;
    padding-right: 0px;
    margin-bottom: 1rem;
}



.collection_total{
    display: grid;
    padding: 2px;
    border: 2px solid #ffa8a8;
    border-radius: 15px;
}

.collection_total label{
    text-align: center;
    font-weight: 500;
    padding: 2px;
}

.collection_total .collection_amount{
    font-size: 25px;
    font-weight: 640;
}

.collection_month{
    margin: 0px;
}

.collection_month div{
    text-align: center;
    line-height: 0.8;
}

.collection_month div label:nth-child(1){
    margin-right: 5px;
}

.collection_client{
    margin-left: 5px;
}

.collection_client label{
    text-align: left;
}

.collection_client div label:nth-child(1){
    width: 71%;
}

.orders-today-container label:nth-child(1){
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 0px;
    line-height: 0.8;
}

.total-order-container{
    max-width: 20%;
}

.total-order-container div{
    display: grid;
    padding: 5px;
    border-radius: 15px;
    text-align: center;
}

.total-order-container div label:nth-child(1){
    font-weight: 700;
}

.total-order-container div label:nth-child(2){
    font-weight: 900;
    font-size: 40px;
}
.form-control {
    margin-top: 10px;
    width: 100%;
}

.table-column-filter-input{
    margin-top: 10px;
    width: 91%;
}


.table-filter-input {
    margin-top: 10px;
    width: 98%;
  }

.rt-resizable-header{
    flex: 500 0 auto !important;
}


.ReactTable .rt-th, .ReactTable .rt-td{
    white-space: break-spaces !important;
}

.collection_data_container {
    gap: 20px; 
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between; 
  }

  .collection_data_container_2 {
    gap: 20px; 
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between; 
  }
  
  .orders-today-container,
  .total-order-container {
    text-align: center; 
    padding: 15px;
    border: 1px solid #ccc; 
    border-radius: 8px; 
    background-color: #f9f9f9;
  }

/* Contenedor principal */
.dashboard-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Tres columnas en pantallas grandes */
    gap: 10px;
    max-width: 1200px;
    margin: 0 auto; /* Centra el contenedor */
  }
  
  /* Tarjeta principal "TOTAL" */
  .dashboard-total {
    background-color: #fff;
    border: 2px solid #ff8084;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Tarjetas secundarias */
  .dashboard-card {
    background-color: #fff;
    border: 2px solid #ff8084;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Media query para dispositivos móviles */
  @media (max-width: 768px) {
    .dashboard-container {
      grid-template-columns: 1fr 1fr;
      font-size: 12px;
      padding: 3px; 
    }
  
    .dashboard-total {
      grid-row: span 2;
      font-size: 12px;
      padding: 3px; 
    }
  
    .dashboard-card {
      grid-column: 2;
      font-size: 12px;
      padding: 3px; 
    }
  }
  
  @media (max-width: 768px){
    .collection_data_container {
        flex-direction: column; 
        align-items: center;
      }
      .total-order-container {
          max-width: 20%;
      }
      .total-order-container div label:nth-child(2) {
          font-size: 20px;
      }
      .total-order-container div label:nth-child(1) {
          font-weight: 700;
          font-size: 15px;
      }
      .table-column-filter-input {
          margin-top: 15px;
          width: 96%;
      }
  }
  
  @media (max-width: 576px) {
    .collection_data_container {
      flex-direction: column; 
      align-items: center;
    }
    .total-order-container {
        max-width: 20%;
    }
    .total-order-container div label:nth-child(2) {
        font-size: 20px;
    }
    .total-order-container div label:nth-child(1) {
        font-weight: 700;
        font-size: 15px;
    }
    .table-column-filter-input {
        margin-top: 15px;
        width: 96%;
    }
  }

  @media (max-width: 992px) {
    .collection_data_container {
      flex-direction: column; 
      font-size: 20px;
      align-items: center;
    }
    .collection_data_container_2 {
        display: grid; 
        font-size: 14px;
        align-items: center;
        justify-content: center;
        gap:20px;
      }
    .total-order-container {
        max-width: 70%;
        
    }
    .col-12 {
        flex: 0 0 100%;
        max-width: 80%;
    }
  }



  